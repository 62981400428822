<script>
  import { Router, Route, router } from "yrv";

  /* -------------------------------------------------------------------------- */
  /*                                   Routes                                   */
  /* -------------------------------------------------------------------------- */
  import About from "./routes/About.svelte";

  /* -------------------------------------------------------------------------- */
  /*                                 Components                                 */
  /* -------------------------------------------------------------------------- */
  import Navigation from "./components/nav/Navigation.svelte";
  import Home from "./routes/Home.svelte";
  import Footer from "./components/Footer.svelte";
  import Offer from "./routes/Offer.svelte";
  import Contact from "./routes/Contact.svelte";

  router.subscribe(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  });
</script>

<Router>
  <Navigation />
  <Route exact component={Home} />
  <Route exact path="/onas" component={About} />
  <Route path="/oferta" component={Offer} />
  <Route path="/kontakt" component={Contact} />
  <Footer />
</Router>

<style lang="postcss" global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  html {
    @apply scroll-smooth;
  }

  ::-webkit-scrollbar {
    @apply w-2 lg:w-3;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-gray;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-navy rounded-full transition-colors;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-black;
  }

  button {
    @apply bg-gray outline-none border-0 text-black;
  }

  a,
  a:visited,
  a:link,
  a:hover,
  a:active {
    @apply text-navy;
  }
</style>
