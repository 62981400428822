<script>
  import { onMount } from "svelte";

  import { fade } from "svelte/transition";
  import ContactMap from "./contact/ContactMap.svelte";

  let displayMessageSent = false;
  let displayMessageSentTimeout = undefined;

  onMount(async () => {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const mailStatus = urlParams.get("mail");

      if (mailStatus) {
        window.history.replaceState(null, null, window.location.pathname);
        displayMessageSent = true;
        displayMessageSentTimeout = setTimeout(() => {
          displayMessageSent = false;
        }, 3000);
      }
    }
  });
</script>

<div
  in:fade
  class="w-full max-w-4xl mx-auto min-h-screen grid items-center px-4"
>
  <div class="pt-28 lg:pt-32">
    <div class="pb-12">
      <h2 class="text-5xl md:text-7xl text-navy text-center font-bold">
        Kontakt
      </h2>
      <h4 class="text-xl text-center mt-4 font-bold">
        Z chęcią odpowiemy na wszystkie pytania
      </h4>
    </div>
    <form
      action="https://api.staticforms.xyz/submit"
      method="post"
      class="relative space-y-3 lg:space-y-4"
    >
      {#if displayMessageSent}
        <div
          in:fade
          out:fade
          class="absolute rounded-xl text-center grid items-center justify-items-center top-0 left-0 right-0 bottom-0 bg-navy text-white"
        >
          <div>
            <h2 class="text-2xl lg:text-3xl font-bold">Dziękujemy</h2>
            <h4 class="text-lg lg:text-xl font-bold mt-5">
              Wiadomość została wysłana
            </h4>
          </div>
        </div>
      {/if}
      <input
        type="hidden"
        name="accessKey"
        value="42f09a85-adc6-4d14-a9a6-4e80c5885207"
      />
      <input
        type="hidden"
        name="redirectTo"
        value="{window.location.href.split('?')[0]}?mail=success"
      />

      <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-4">
        <input
          class="py-4 px-6 rounded-xl bg-gray border-white border-2 text-lg"
          type="text"
          placeholder="Imię i Nazwisko"
          name="name"
          id="form__name"
        />
        <input
          class="py-4 px-6 rounded-xl bg-gray border-white border-2 text-lg"
          type="email"
          placeholder="Email"
          name="email"
          id="form__email"
        />
      </div>
      <textarea
        class="py-6 px-4 w-full rounded-xl bg-gray border-white border-2 text-lg resize-none"
        placeholder="Słuchamy uważnie..."
        name="message"
        id="form__message"
        rows="8"
      />
      <button
        class="py-4 px-6 rounded-xl bg-navy text-white font-bold border-white border-2 text-lg w-full"
        >Wyślij</button
      >
      <p class="text-lg text-center text-darkGrey">
        Wysyłając wyrażam zgodę na przetwarzanie moich danych osobowych,
        podanych w powyższym formularzu w celu udzielenia odpowiedzi na wpisane
        zapytanie przez "Doradztwo CUD - Agnieszka Szczucińska"
      </p>
    </form>
    <hr
      class="border-gray block max-w-xs lg:max-w-3xl mx-auto my-12 lg:my-24"
    />
  </div>
  <div>
    <h2 class="text-center text-xl lg:text-3xl font-bold text-navy">
      Jesteśmy tutaj!
    </h2>
    <address class="text-center text-xl mb-8 lg:mb-16 mt-6">
      ul. Edukacji 14<br />39-400 Tarnobrzeg
    </address>
    <ContactMap />
    <hr
      class="border-gray block max-w-xs lg:max-w-3xl mx-auto my-12 lg:my-24"
    />
  </div>
  <div class="mx-auto">
    <h2 class="text-center text-xl lg:text-3xl font-bold text-navy">
      Dodatkowe informacje
    </h2>
    <ul class="text-xl text-center space-y-2 lg:space-y-3 mt-6 lg:mt-8 mx-auto">
      <li>
        Email: <a href="mailto:agnieszka@doradztwocud.pl"
          >agnieszka@doradztwocud.pl</a
        >
      </li>
      <li>
        Tel. kom. <a href="phone:505041260">505 041 260</a>
      </li>
      <li>NIP: 8671102192</li>
      <li>Regon: 181014993</li>
      <!-- <li>Numer konta: ...</li> -->
    </ul>
  </div>
</div>
