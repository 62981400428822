<script>
  import { Link } from "yrv";
  import ArrowRightIcon from "../icons/ArrowRightIcon.svelte";
  export let href = "/";
  export let _class;
</script>

<Link
  {href}
  class="inline-block px-8 py-4 bg-gray rounded font-semibold {_class} space-y-4 group"
>
  <slot /><ArrowRightIcon _class="ml-4 group-hover:animate-bouncex" />
</Link>
