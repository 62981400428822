<script>
  import { Link } from "yrv";
  import { elasticInOut } from "svelte/easing";
  import { scale, fly } from "svelte/transition";
  import CheckIcon from "../../components/icons/CheckIcon.svelte";
  import CTALink from "../../components/base/CTALink.svelte";
  import HomeChart from "../../components/svg/HomeChart.svelte";
  import { onMount } from "svelte";
</script>

<section
  class="relative h-auto w-full lg:h-96 bg-navy mt-14 md:mt-32 overflow-hidden"
>
  <div
    class="relative z-10 grid grid-cols-1 lg:grid-cols-2 h-full w-full items-center justify-items-center text-black after:w-full after:h-[1200px] right-0 lg:after:right-1/2 lg:after:-mr-40 after:bottom-0 lg:after:-bottom-24 lg:after:rounded-full after:absolute after:bg-gray after:-z-10"
  >
    <div class="w-full lg:max-w-lg px-6 lg:px-0 py-12 lg:py-0">
      <q class="text-2xl lg:text-3xl leading-relaxed"
        >Wsłuchiwanie się w głos klientów powinno stać się biznesem każdej firmy</q
      >
      <p class="text-2xl mt-3 font-serif text-right font-bold">~ Tom Peters</p>
    </div>
    <div class="pb-12 lg:py-0">
      <CTALink
        href="/kontakt"
        _class="text-2xl bg-transparent lg:text-gray lg:hover:text-gray lg:visited:text-gray"
        >Skontaktuj się z nami</CTALink
      >
    </div>
  </div>
</section>
