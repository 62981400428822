<script>
  import IntroSection from "./home/IntroSection.svelte";
  import AboutSection from "./home/AboutSection.svelte";

  import Rellax from "rellax";
  import { onMount } from "svelte";
  import OfferSection from "./home/OfferSection.svelte";
  import QuoteSection from "./home/QuoteSection.svelte";

  onMount(() => {
    new Rellax(".rellax");
  });
</script>

<IntroSection />
<AboutSection />
<OfferSection />
<QuoteSection />
