<script>
  import { fade, fly } from "svelte/transition";
</script>

<section class="w-full h-full min-h-screen overflow-hidden mt-10">
  <div
    class="grid grid-cols-1 md:grid-cols-2 w-full h-full place-items-center items-center justify-items-center auto-rows-min md:auto-rows-auto"
  >
    <div class="px-8 md:px-20 mt-16 md:mt-0">
      <h2
        in:fade={{ duration: 1900 }}
        class="text-5xl md:text-7xl text-navy text-center"
      >
        W czym możemy pomóc?
      </h2>
    </div>
    <div class="pt-20 md:pt-0">
      <ul class="text-xl md:text-3xl text-left space-y-4 md:px-0 px-8">
        <li in:fly={{ x: 200, delay: 0, duration: 1000 }}>
          <a href="#section1"> Zakładam działalność gospodarczą →</a>
        </li>
        <li in:fly={{ x: 200, delay: 300, duration: 1000 }}>
          <a href="#section2"> Prowadzę firmę →</a>
        </li>
        <li in:fly={{ x: 200, delay: 600, duration: 1000 }}>
          <a href="#section3"> Biuro Rachunkowe →</a>
        </li>
      </ul>
    </div>
  </div>
</section>
