<script>
  import { scale, fly, slide, fade } from "svelte/transition";
</script>

<section class="relative min-h-screen w-full">
  <div class="h-screen" />
  <div class="absolute top-0 h-screen w-full grid items-center">
    <div class="flex mx-auto gap-16">
      <p
        in:fly={{ x: 100, duration: 1300, delay: 0 }}
        class="text-7xl xl:text-[300px] font-bold text-navy"
      >
        C
      </p>
      <p
        in:fly={{ x: 100, duration: 1300, delay: 400 }}
        class="text-7xl xl:text-[300px] font-bold text-navy"
      >
        U
      </p>
      <p
        in:fly={{ x: 100, duration: 1300, delay: 800 }}
        class="text-7xl xl:text-[300px] font-bold text-navy"
      >
        D
      </p>
    </div>
  </div>
  <div
    in:fade={{ duration: 2000, delay: 800 }}
    class="w-11/12 max-w-4xl mx-auto bg-gray rounded-xl -mt-64 z-50 rellax"
    data-rellax-speed="4"
    data-rellax-percentage="0.1"
    data-rellax-tablet-speed="0"
  >
    <!-- <img
      class="object-cover w-full h-96 rounded-t-xl"
      src="images/about_001.jpg"
      alt="O nas"
    /> -->
    <div class="px-3 md:px-8 w-full lg:px-24 py-8 lg:py-24">
      <!-- <div class="font-serif">
        <h5 class="text-4xl mb-6 font-sans text-left">To my</h5>
        <button
          class="ml-4 text-6xl bg-transparent font-bold self-end text-right col-span-3 hover:underline hover:text-darkGrey"
          >Agnieszka</button
        ><span class="text-6xl font-bold mx-4 text-center">i</span><button
          class="text-6xl bg-transparent font-bold self-start text-left col-span-3 hover:underline hover:text-darkGrey"
          >Piotr</button
        >
        <h5 class="text-2xl mt-4 font-sans text-right pl-12">
          Oboje z długoletnim<br />doświadczeniem w pracy <br />zawodowej
        </h5>
      </div> -->
      <p class="text-lg mt-4 lg:mt-8">
        Nasze usługi skierowane są do osób chcących założyć działalność
        gospodarczą oraz dla firm, które chcą prowadzić swoją działalność
        zgodnie z obowiązującymi przepisami oraz rozwijać swoją działalności i
        zwiększać swoje przychody.
      </p>
      <h2 class="text-4xl font-serif mt-4 lg:mt-24">Agnieszka</h2>
      <p class="text-lg mt-4 lg:mt-6">
        wykształcenie wyższe – zarządzanie i marketing AGH w Krakowie. 30 lat
        doświadczenia zawodowego w dziedzinach:
      </p>
      <ul class="list-disc pl-2 lg:pl-6 mt-2 text-lg space-y-1">
        <li>marketingu</li>
        <li>finansów</li>
        <li>pozyskiwania zewnętrznych źródeł finansowania inwestycji</li>
        <li>zarządzania projektami unijnymi</li>
        <li>współpracy z samorządami i organizacjami pozarządowymi</li>
        <li>
          prowadzenia szkoleń i udzielania doradztwa w zakresie pozyskiwania
          dotacji z funduszy unijnych oraz opracowywania wniosków i biznesplanów
          dla firm na rozpoczęcie działalności gospodarczej lub ich rozwoju
        </li>
      </ul>
    </div>
  </div>
</section>
