<script>
  import CheckIcon from "../../components/icons/CheckIcon.svelte";
  import OfferSectionBase from "./OfferSectionBase.svelte";
</script>

<OfferSectionBase>
  <div class="md:block hidden">
    <img
      class="w-4/5 mx-auto rellax"
      data-rellax-speed="2"
      data-rellax-percentage="0.5"
      src="./images/offer_002.png"
      alt="Oferta dla nowych i istniejących firm"
    />
  </div>
  <div
    class="max-w-3xl bg-gray px-4 py-8 lg:px-24 lg:py-12 rounded-lg  leading-relaxed justify-self-start"
  >
    <h2 class="text-xl lg:text-3xl font-bold text-left text-navy">
      Prowadzę firmę
    </h2>
    <p class="text-xl mt-6 lg:mt-8">
      Masz już własną firmę? Potrzebujesz dobrego doradcy, czujesz że można
      wyciągnąć z tego więcej? Nasza odpowiedź jest prosta: można, potrzeba
      tylko cudu, a tak się składa, że to właśnie my.
      <br />
      <br />
      A to jedne z niewielu rzeczy jakie możemy dla ciebie zrobić, żeby było nie
      tylko lepiej, ale cudownie:
    </p>
    <ul class="mt-8 space-y-4 text-lg">
      <li>
        <CheckIcon />doradztwo finansowe i marketingowe
      </li>
      <li>
        <CheckIcon />pozyskiwanie zewnętrznych źródeł finansowania inwestycji
      </li>
      <li>
        <CheckIcon />wypełnianie wniosków związanych z dotacjami, pożyczkami,
        kredytami, leasingami
      </li>
      <li>
        <CheckIcon />konsultacje i doradztwa biznesowe i informatyczne w
        zakresie obszaru finanse
      </li>
      <li>
        <CheckIcon />opracowywanie i wdrażanie procesów biznesowych
      </li>
      <li>
        <CheckIcon />i wiele więcej...
      </li>
    </ul>
  </div>
  <div class="md:hidden mt-12 px-8">
    <img
      class="w-4/5 mx-auto"
      src="./images/offer_002.png"
      alt="Oferta dla nowych i istniejących firm"
    />
  </div>
</OfferSectionBase>
