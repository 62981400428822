<script>
  import CheckIcon from "../../components/icons/CheckIcon.svelte";
</script>

<section id="section3" class="w-full mt-10 md:px-0 px-4">
  <div class="max-w-lg md:max-w-4xl mx-auto">
    <div
      class="bg-gray px-4 py-8 lg:px-24 lg:py-12 rounded-lg  leading-relaxed"
    >
      <h2 class="text-xl lg:text-3xl font-bold text-center text-navy">
        Biuro Rachunkowe
      </h2>
      <p class="text-xl mt-6 lg:mt-8">
        Oferujemy w pełni profesjonalną i rzetelną obsługę księgową. Na każdej
        płaszczyźnie chcemy utrzymywać najwyższą jakość. Tak samo jeśli chodzi o
        nasze oprogramowanie, i to w jaki sposób przechowujemy dane. Nasze Biuro
        Rachunkowe oparte jest na oprogramowaniu COMARCH, a twoje dane zawsze
        bezpieczne wraz z kopią zapasową.
        <br />
        Dostosowujemy nasze usługi do indywidualnych potrzeb i oczekiwań Klientów.
        <br />
        Nasza oferta przeznaczona jest zarówno dla małych jak i dla średnich i dużych
        firm.
      </p>
      <div class="mt-8 text-lg">
        <b>Księgowość - rozliczenie PIT, CIT</b>
        <ul class="space-y-4 mt-4">
          <li>
            <CheckIcon />prowadzenie ksiąg rachunkowych
          </li>
          <li>
            <CheckIcon />prowadzenie podatkowej książki przychodów i rozchodów
          </li>
          <li>
            <CheckIcon />prowadzenie ewidencji vat
          </li>
          <li>
            <CheckIcon />prowadzenie ewidencji środków trwałych i wartości
            niematerialnych i prawnych oraz ewidencji wyposażenia
          </li>
          <li>
            <CheckIcon />rozliczanie podatków PIT, CIT, VAT
          </li>
          <li>
            <CheckIcon />przygotowywanie i przekazywanie deklaracji do US
          </li>
          <li>
            <CheckIcon />przygotowywanie sprawozdań finansowych oraz planu kont
            oraz polityki rachunkowości
          </li>
        </ul>
      </div>

      <div class="mt-8 text-lg">
        <b>Kadry i płace - obsługa kadrowo płacowa</b>
        <ul class="space-y-4 mt-4">
          <li>
            <CheckIcon />prowadzenie dokumentacji pracowniczej oraz akt
            osobowych
          </li>
          <li>
            <CheckIcon />przygotowywanie i sporządzanie list płac
          </li>
          <li>
            <CheckIcon />rozliczanie umów cywilnoprawnych
          </li>
          <li>
            <CheckIcon />prowadzenie rozliczeń z Urzędem Skarbowym i Zakładem
            Ubezpieczeń Społecznych
          </li>
        </ul>
      </div>

      <p class="text-xl mt-8">
        W zależności od potrzeb i wcześniejszych uzgodnień istnieje możliwość
        odbioru dowodów księgowych prosto z Państwa firmy bez konieczności
        przekazywania ich osobiście do biura. Istnieje również możliwość
        przekazywania dokumentów drogą elektroniczną. Koszty obsługi ustalane są
        indywidualnie z każdym Klientem.
        <br />
        <br />
        Zawsze jesteśmy do Państwa dyspozycji, pochylimy się nad każdym problemem
        wspólnie go rozwiązując, aby każdy nasz Klient odniósł sukces w swojej branży.
      </p>
    </div>
  </div>
</section>
