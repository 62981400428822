<script>
  import { elasticOut } from "svelte/easing";

  function grow(node, { duration, delay }) {
    return {
      delay,
      duration,
      css: (t) => {
        const eased = elasticOut(t);

        return `
					height: ${t * 468}px
          `;
      },
    };
  }
</script>

<svg
  in:grow={{ duration: 900, delay: 1500 }}
  preserveAspectRatio="none"
  width="1057"
  height="468"
  class="max-w-full"
  viewBox="0 0 1057 468"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <mask
    id="mask0_232_626"
    style="mask-type:alpha"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="1057"
    height="468"
  >
    <rect width="1057" height="468" fill="#C4C4C4" />
  </mask>
  <g mask="url(#mask0_232_626)">
    <path
      d="M175.803 428.009L121.638 397.822C104.553 388.3 83.2737 390.905 68.9899 404.267L-25.8704 493.006C-26.1906 493.305 -26.5394 493.573 -26.9119 493.804C-32.6336 497.357 -30.1157 506.184 -23.3805 506.184H1076.42C1101.27 506.184 1121.42 486.037 1121.42 461.184V38.597C1121.42 3.99652 1083.99 -17.6592 1053.99 -0.419678L1031.02 12.7831C1025.47 15.9732 1020.66 20.3112 1016.92 25.5074L969.453 91.4388C960.997 103.185 947.407 110.146 932.933 110.146H921.843C907.424 110.146 893.879 117.056 885.414 128.728L854.706 171.071C839.959 191.406 811.421 195.731 791.312 180.679L749.443 149.34C731.152 135.65 705.506 137.838 689.799 154.429L644.323 202.465C640.82 206.164 636.715 209.242 632.182 211.567L577.689 239.52C568.592 244.186 558.178 245.615 548.16 243.573L528.895 239.645C513.236 236.452 497.058 241.804 486.392 253.706L402.984 346.785C389.389 361.956 367.268 366.039 349.154 356.72C333.181 348.503 313.843 350.626 300.034 362.114L226.489 423.296C212.191 435.19 192.049 437.063 175.803 428.009Z"
      fill="url(#paint0_linear_232_626)"
    />
  </g>
  <defs>
    <linearGradient
      id="paint0_linear_232_626"
      x1="1121.42"
      y1="-39.1632"
      x2="289.824"
      y2="828.762"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#FF0000" />
      <stop offset="1" stop-color="#00095C" stop-opacity="0.83" />
    </linearGradient>
  </defs>
</svg>
