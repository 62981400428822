<script>
  import { Link } from "yrv";
</script>

<section class="py-24 max-w-5xl mx-auto">
  <div class="text-center">
    <img class="mx-auto h-32" src="./images/logo.png" alt="CUD" />
    <h5 class="mt-1 text-navy font-bold text-lg">Centrum Usług Doradczych</h5>
    <h6 class="mt-1 text-navy text-md">Agnieszka Szczucińska</h6>
  </div>
  <div class="mt-24 grid grid-cols-1 md:grid-cols-2 items-center">
    <div>
      <ul
        class="space-y-4 font-bold text-navy text-lg text-center lg:text-left"
      >
        <li>
          Email: <a href="mailto:agnieszka@doradztwocud.pl"
            >agnieszka@doradztwocud.pl</a
          >
        </li>
        <li>
          Tel. kom. <a href="phone:505041260">505 041 260</a>
        </li>
        <li>NIP: 8671102192</li>
        <li>Regon: 181014993</li>
        <li>
          Adres: ul. Edukacji 14<br />39-400 Tarnobrzeg
        </li>
      </ul>
    </div>
    <nav class="space-x-4 text-xl text-center lg:text-right mt-12 lg:mt-0">
      <Link href="/onas">o nas</Link>
      <Link href="/oferta">oferta</Link>
      <Link href="/kontakt">kontakt</Link>
    </nav>
  </div>

  <p class="pt-36 pb-16 text-xl font-bold text-navy text-center group px-4">
    Wszelkie prawa zastrzeżone. Doradztwo CUD - Agnieszka Szczucińska <br /><br
    />
    <!-- Wykonane z
    <span class="inline-block group-hover:animate-ping">❤️</span>
    przez
    <a class="underline" href="https://dsgn.mynth.io">dsgn.mynth.io</a> -->
  </p>
</section>
