<script>
  import { Link } from "yrv";
  import { elasticInOut } from "svelte/easing";
  import { scale, fly } from "svelte/transition";
  import CheckIcon from "../../components/icons/CheckIcon.svelte";
  import CTALink from "../../components/base/CTALink.svelte";
  import HomeChart from "../../components/svg/HomeChart.svelte";
  import { onMount } from "svelte";
</script>

<div class="relative w-full mt-8 md:pt-36 md:pb-28">
  <div
    class="h-full max-w-sm md:max-w-max mx-auto grid grid-cols-12 justify-center w-full items-baseline auto-rows-min md:auto-rows-auto md:items-center"
  >
    <!-- <div class="hidden md:block col-span-6 w-full">
      <div class="relative w-8/12 mx-auto">
        <img
          class="absolute -z-50 -left-36 -top-36 rellax"
          data-rellax-speed="-3"
          src="./images/light_001.png"
          alt="CUD"
        />
        <img
          class="w-full h-full object-cover rounded rellax"
          data-rellax-speed="1"
          src=""
          alt="Agnieszka"
        />
        <div
          class="bg-navy text-white px-4 py-6 rounded absolute -bottom-10 -right-4 max-w-md"
        >
          <h3 class="text-xl font-bold text-opacity-70">Agnieszka</h3>
          <h4 class="text-md text-gray text-opacity-60 font-semibold">
            Założycielka firmy
          </h4>
          <p class="text-gray text-opacity-70 mt-2">
            Mama 2 wspaniałych i cudownych chłopaków. Mimo swojego młodego
            wyglądu ma 30 lat doświadczenia zawodowego w dziedzinie marketingu i
            finansów.
          </p>
        </div>
      </div>
    </div> -->
    <div class="md:col-span-12 col-span-12 md:p-0 px-2 md:ml-0">
      <h2 class="text-center w-full text-5xl font-bold text-navy px-4 md:px-0">
        Kim jesteśmy?
      </h2>
      <div class="px-2 md:px-6 space-y-8 text-lg mt-12 max-w-2xl mx-auto">
        <p>
          CUD, czyli Centrum Usług Doradczych to firma działającą na rynku od
          lipca 2021 roku.
        </p>
        <p>
          Pomimo krótkiego okresu działalności firmy, jako osoby ją tworzące,
          posiadamy wieloletnie doświadczenie w dziedzinie finansów i
          rachunkowości oraz marketingu, pozyskiwaniu zewnętrznych źródeł
          finansowania inwestycji, controllingu, zarządzania projektami,
          współpracy z samorządami i organizacjami pozarządowymi, prowadzenia
          szkoleń, opracowywania wniosków i biznesplanów i wiele więcej.
        </p>
        <p>
          Nasze usługi skierowane są do osób chcących założyć działalność
          gospodarczą oraz dla firm, które chcą prowadzić swoją działalność
          zgodnie z obowiązującymi przepisami oraz rozwijać się i zwiększać
          swoje przychody.
        </p>
        <!-- <CTALink href="/onas">Więcej informacji o nas i o firmie</CTALink> -->
      </div>
    </div>
  </div>
</div>
