<script>
  import IntroAbout from "./about/IntroAbout.svelte";
  import Rellax from "rellax";
  import { onMount } from "svelte";
  import AboutPerson from "./about/AboutPerson.svelte";
  import IntroOffer from "./offer/IntroOffer.svelte";
  import OfferSectionOne from "./offer/OfferSectionOne.svelte";
  import OfferSectionTwo from "./offer/OfferSectionTwo.svelte";
  import OfferSectionThree from "./offer/OfferSectionThree.svelte";

  onMount(() => {
    new Rellax(".rellax");
  });
</script>

<IntroOffer />
<OfferSectionOne />
<hr
  id="section2"
  class="border-gray block max-w-xs lg:max-w-3xl mx-auto my-12 lg:my-32"
/>
<OfferSectionTwo />
<hr
  id="section3"
  class="border-gray block max-w-xs lg:max-w-3xl mx-auto my-12 lg:my-32"
/>
<OfferSectionThree />
<!-- <hr class="border-gray block max-w-xs lg:max-w-3xl mx-auto my-12 lg:my-32" /> -->
