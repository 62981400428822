<script>
  import IntroAbout from "./about/IntroAbout.svelte";
  import Rellax from "rellax";
  import { onMount } from "svelte";
  import AboutPerson from "./about/AboutPerson.svelte";

  onMount(() => {
    new Rellax(".rellax");
  });
</script>

<IntroAbout />
<AboutPerson />
