<script>
  import { Link } from "yrv";
  import { elasticInOut } from "svelte/easing";
  import { scale, fly } from "svelte/transition";
  import CheckIcon from "../../components/icons/CheckIcon.svelte";
  import CTALink from "../../components/base/CTALink.svelte";
  import HomeChart from "../../components/svg/HomeChart.svelte";
</script>

<div
  class="relative w-full lg:min-h-screen lg:h-full pt-14 md:pt-0 overflow-hidden"
>
  <div
    class="h-full grid grid-cols-12 items-baseline auto-rows-min md:auto-rows-auto md:items-center"
  >
    <div class="col-span-12 md:col-span-6 order-2 md:order-1 w-full">
      <div class="max-w-xl mx-auto px-4">
        <h1
          class="font-bold text-3xl md:text-5xl"
          in:fly={{ x: -100, duration: 1000 }}
        >
          Finanse i księgowość w odpowiednich rękach
        </h1>
        <ul class="px-2 mt-6 text-xl space-y-3 mb-6">
          <li in:fly={{ x: -100, duration: 1000, delay: 100 }}>
            <CheckIcon />30 lat doświadczenia w zawodzie
          </li>
          <li in:fly={{ x: -100, duration: 1000, delay: 500 }}>
            <CheckIcon />Ciągły rozwój i nauka
          </li>
          <li in:fly={{ x: -100, duration: 1000, delay: 900 }}>
            <CheckIcon />Profesjonalne oprogramowanie
          </li>
        </ul>
        <div
          in:fly={{ y: 200, duration: 1000, delay: 1100 }}
          class="mx-auto text-center pt-6"
        >
          <CTALink href="/oferta">Poznaj naszą ofertę</CTALink>
        </div>
      </div>
    </div>
    <div
      class="col-span-12 md:col-span-6 order-1 md:order-2 px-8 p-4 md:pr-12 md:pl-0 md:px-0"
    >
      <img
        in:fly={{ x: 200, delay: 200, duration: 1000 }}
        src="./images/home_001.jpg"
        alt="Finanse i księgowość w odpowiednich rękach"
        class="rounded-lg rellax"
        data-rellax-speed="2"
      />
    </div>
  </div>
  <div
    class="absolute bottom-0 left-2 right-2 md:left-auto md:bottom-6 md:right-6 -z-50 hidden sm:block"
  >
    <HomeChart />
  </div>
</div>
