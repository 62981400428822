<script>
  import { Link } from "yrv";
  import { elasticInOut } from "svelte/easing";
  import { scale, fly } from "svelte/transition";
  import CheckIcon from "../../components/icons/CheckIcon.svelte";
  import CTALink from "../../components/base/CTALink.svelte";
  import HomeChart from "../../components/svg/HomeChart.svelte";
  import { onMount } from "svelte";
</script>

<div class="relative w-full min-h-screen mt-14 md:mt-32 md:pt-20">
  <img
    class="absolute -z-50 lg:-left-72 -left-96 -top-40 rellax"
    data-rellax-percentage="0.5"
    data-rellax-speed="-3"
    src="./images/light_001.png"
    alt="CUD"
  />
  <img
    class="absolute -z-50 -left-96 -top-60 rellax hidden lg:block"
    data-rellax-percentage="0.5"
    data-rellax-speed="-1"
    src="./images/u_001.png"
    alt="CUD"
  />

  <!-- SECTION -->
  <h2
    class="text-center w-full text-5xl font-bold text-navy px-4 md:px-0 mx-auto max-w-xl"
  >
    Jakie cuda możemy ci zaoferować?
  </h2>
  <div
    class="mx-auto max-w-4xl grid md:grid-cols-2 px-8 md:px-0 grid-cols- justify-items-center text-center gap-8 md:gap-16 mt-16"
  >
    <div>
      <img
        class="mx-auto h-40"
        src="./images/home_003.png"
        alt="Dla firm i tych co zaczynają"
      />
      <p class="text-lg font-bold mt-8">
        Doradztwo dla firm i tych którzy dopiero zaczynają
      </p>
    </div>
    <div>
      <img
        class="mx-auto h-40"
        src="./images/home_005.png"
        alt="Dla firm i tych co zaczynają"
      />
      <p class="text-lg font-bold mt-8">Biuro Rachunkowe</p>
    </div>
    <div>
      <img
        class="mx-auto h-40"
        src="./images/home_004.png"
        alt="Dla firm i tych co zaczynają"
      />
      <p class="text-lg font-bold mt-8">
        Stały kontakt telefoniczny, e-mailowy oraz osobisty
      </p>
    </div>
    <div>
      <img
        class="mx-auto h-40"
        src="./images/home_006.png"
        alt="Dla firm i tych co zaczynają"
      />
      <p class="text-lg font-bold mt-8">
        Używamy Profesjonalnego oprogramowania COMARCH
      </p>
    </div>
  </div>
  <div class="text-center w-full pt-12">
    <CTALink href="/oferta">Szczegóły i pełna oferta</CTALink>
  </div>
</div>
