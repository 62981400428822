<script>
  import { fade, fly } from "svelte/transition";
  import { Link, router } from "yrv";

  import MenuIcon from "../icons/MenuIcon.svelte";

  let isMobileNavigationActive = false;

  router.subscribe((e) => {
    if (!e.initial) isMobileNavigationActive = false;
  });
</script>

<div
  in:fly={{ y: -70, duration: 1200 }}
  out:fade
  class="fixed top-0 w-full h-16 md:h-24 flex items-center justify-center md:justify-between px-8 z-50 hover:bg-gray transition-colors duration-500"
>
  <div class="flex items-center">
    <Link href="/">
      <img alt="CUD" src="./images/logo.png" class="h-14" />
    </Link>
    <nav class="ml-24 hidden md:block font-semibold space-x-16">
      <Link href="/onas">o nas</Link>
      <Link href="/oferta">oferta</Link>
      <Link href="/kontakt">kontakt</Link>
    </nav>
  </div>
  <div class="hidden md:block space-x-4">
    <a href="tel:505041260">tel. kom. 505 041 260</a>
    <a href="mailto:agnieszka@doradztwocud.pl"
      >mail: agnieszka@doradztwocud.pl</a
    >
  </div>
</div>

{#if isMobileNavigationActive}
  <nav
    in:fly={{ x: -100, duration: 700 }}
    out:fly={{ x: 100, duration: 700 }}
    class="fixed bottom-20 right-4 bg-gray rounded-lg py-8 w-80 z-50 shadow-xl"
  >
    <ul class="text-center text-xl font-bold space-y-3">
      <li>
        <Link
          class="text-navy visited:text-navy hover:text-navy {$router.path ===
          '/onas'
            ? 'underline'
            : ''}"
          href="/onas">o nas</Link
        >
      </li>
      <li>
        <Link
          class="text-navy visited:text-navy hover:text-navy {$router.path ===
          '/oferta'
            ? 'underline'
            : ''}"
          href="/oferta">oferta</Link
        >
      </li>
      <li>
        <Link
          class="text-navy visited:text-navy hover:text-navy {$router.path ===
          '/kontakt'
            ? 'underline'
            : ''}"
          href="/kontakt">kontakt</Link
        >
      </li>
    </ul>
  </nav>
{/if}

<button
  class="md:hidden fixed bottom-4 right-4 bg-gray flex items-center gap-4 px-6 py-4 rounded text-black font-bold z-50"
  on:click={() => (isMobileNavigationActive = !isMobileNavigationActive)}
>
  Menu <MenuIcon /></button
>
