<script>
  import CheckIcon from "../../components/icons/CheckIcon.svelte";
  import OfferSectionBase from "./OfferSectionBase.svelte";
</script>

<OfferSectionBase id="section1">
  <div
    class="max-w-3xl bg-gray px-4 lg:px-24 py-12 rounded-lg  leading-relaxed"
  >
    <h2 class="text-xl lg:text-3xl font-bold text-left text-navy">
      Zakładam działalność gospodarczą
    </h2>
    <p class="text-xl mt-6 lg:mt-8">
      Jeśli zastanawiasz się nad założeniem działalności gospodarczej lub masz
      to już w swoich planach, to są rzeczy które możemy dla ciebie zrobić
    </p>
    <ul class="mt-8 space-y-4 text-lg">
      <li>
        <CheckIcon />poszukiwanie środków zewnętrznych na uruchomienie
        działalności gospodarczej
      </li>
      <li>
        <CheckIcon />pomoc w planowaniu i zakładaniu działalności gospodarczej
      </li>
      <li>
        <CheckIcon />opracowywanie biznesplanów pomoc w realizacji projektów i
        ich rozliczaniu
      </li>
      <li>
        <CheckIcon />wybór odpowiedniej formy działalności i opodatkowania
      </li>
    </ul>
    <p class="text-xl mt-8">
      Oprócz tego, na każdym etapie swojej nowej i cudownej przygody, będziesz
      mógł liczyć na naszą pomoc
    </p>
  </div>
  <div class="hidden md:block w-full">
    <img
      class="object-contain md:w-4/5 max-h-[450px] rellax"
      data-rellax-speed="2"
      data-rellax-percentage="0.5"
      data-rellax-tablet-speed="0"
      src="./images/offer_001.png"
      alt="Oferta dla nowych i istniejących firm"
    />
  </div>
  <div class="md:hidden mt-12 px-8">
    <img
      class="w-4/5 mx-auto"
      src="./images/offer_001.png"
      alt="Oferta dla nowych i istniejących firm"
    />
  </div>
</OfferSectionBase>
