<script>
  export let id;
</script>

<section {id} class="w-full mt-10 md:px-0 px-2">
  <div
    class="md:grid md:grid-cols-2 w-full h-auto items-center justify-items-center auto-rows-min"
  >
    <slot />
  </div>
</section>
